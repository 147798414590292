import React, { useEffect, useState } from 'react';
import config from "grafana/app/core/config";


import { BusEventBase, CoreApp, DataFrame, dateTime, DisplayProcessor, Field, getFieldSeriesColor, GrafanaTheme2, GrafanaThemeType, GraphSeriesXY, PanelData, PanelProps, SelectableValue, ThresholdsMode, TimeRange, VizOrientation } from '@grafana/data';
import { Select, Input, MultiSelect, Table, InteractiveTable, BarGauge, Tooltip, TooltipPlugin, Graph, GraphNG, useTheme2, LegendDisplayMode, UPlotConfigBuilder, usePanelContext, VizTooltip, TooltipDisplayMode, TooltipPlugin2, PlotLegend, VizLegend, VizTooltipContainer, Portal, TimeSeries, BarAlignment, GraphFieldConfig, StackingMode, Popover } from '@grafana/ui';
import { FilterPanelOptions, InputType, MultiSelectFilterOptions, SelectFilterOptions, ThroughputListFilterOptions } from '../../types';
import { getDisplayProcessor } from '@grafana/data';
import { testIds } from '../testIds';
import { DataSourcePicker, locationService, getDataSourceSrv, getTime } from '@grafana/runtime';
import { BarGaugeNamePlacement, BarGaugeValueMode, GraphDrawStyle, Panel, VisibilityMode } from '@grafana/schema';

interface Props extends PanelProps<FilterPanelOptions> {}

function assertOptions<T extends FilterPanelOptions>(options: FilterPanelOptions, inputType: T["inputType"]): asserts options is T {
  if (options.inputType !== inputType) {
    throw new Error("Invalid Options");
  }
}

function getLabelsFromDataFrame(dataFrame: DataFrame, fieldName: string | undefined): SelectableValue[] {
  let field = dataFrame.fields.find(({ name, type }) => {
    if (fieldName) {
      return name === fieldName;
    }
    return type === "string"
  });

  if (!field) {
    field = dataFrame.fields.find(({ type }) => type !== 'time');
  }

  if (!field) {
    return [];
  }
  // might be an array, or a { buffer: array } - either way, this returns an array.
  return Array.from(new Set(field.values.toArray())).map((value) => ({ label: value, value: `${value}` }));
}


function getOptions(options: SelectFilterOptions | MultiSelectFilterOptions, series: DataFrame[]): SelectableValue[] {
  if (options.dataSource === "list") {
    return options.optionsCSV?.split("\n").map(kv => {
      const [value, label] = kv.split(",");
      return {
        value,
        label: label || value
      };
    }) || []
  }
  if (options.dataSource === "query") {
    const dataFrame = series.find(({ refId }) => options.queryRefId ? refId === options.queryRefId : true);
    if (!dataFrame) {
      return [];
    }
    return getLabelsFromDataFrame(dataFrame, options.queryFieldName);
  }
  throw new Error("Unexpected data type");
}

function renderSelect({ options, data }: Props): React.JSX.Element {
  assertOptions<SelectFilterOptions>(options, 'select');
  const selectableOptions = getOptions(options, data.series);
  const urlParam = locationService.getSearchObject()[`var-${options.variableName}`] as string;

  return (
    <Select
      options={selectableOptions}
      defaultValue={urlParam || options.defaultValue}
      onChange={(value) => {
        locationService.partial({
          [`var-${options.variableName}`]: value?.value
        })
      }}
    />
  )
}

function renderMultiSelect({ options, data }: Props): React.JSX.Element {
  assertOptions<MultiSelectFilterOptions>(options, "multiselect");
  const selectableOptions = getOptions(options, data.series);

  const urlParam = locationService.getSearchObject()[`var-${options.variableName}`] as string;
  let values = urlParam?.split('|');

  if (!values.length && options.defaultValue) {
    values = [options.defaultValue];
  }

  return (
    <MultiSelect
      options={selectableOptions}
      allowCustomValue
      defaultValue={values}
      onChange={(values) => {
        locationService.partial({
          [`var-${options.variableName}`]: values.map(({ value }) => value).join('|')
        })
      }}
    />
  );
}

function renderInput({ options }: Props): React.JSX.Element {
  assertOptions(options, "input");
  const urlParam = locationService.getSearchObject()[`var-${options.variableName}`] as string;
  return (
    <Input
      defaultValue={urlParam || options.defaultValue}
      onChange={(event) => {
        locationService.partial({
          [`var-${options.variableName}`]: event.currentTarget.value
        });

        event.currentTarget.setAttribute('value', event.currentTarget.value);
      }}
    />
  )
}

function renderThroughputList({ options, width, data, height, timeRange }: Props): React.JSX.Element {
  return (<ThroughputList
    data={data}
    options={options}
    width={width}
    height={height}
    timeRange={timeRange}
  />)
}


type JSXFunction = (arg0: Props) => React.JSX.Element

const renderFunctions: {[k in InputType]: JSXFunction} = {
  input: renderInput,
  select: renderSelect,
  multiselect: renderMultiSelect,
  throughputlist: renderThroughputList
};

export function FilterPanel({
  // Takes in a list of props used in this example
  options, // Options declared within module.ts and standard Grafana options
  data,
  ...rest
}: Props) {
  const ret = renderFunctions[options.inputType]({ options, data, ...rest});
  return (
    <div
      data-testid={testIds.panel.container}
      style={{
        height: "100%",
        overflowY: "auto"
      }}
    >
      {ret}
    </div>
  );
}

function ValuedBarGuage({
  color,
  value,
  max,
  width,
  display,
  longestValue
}: { color: string, value: number, max: number, width: number, title?: string, display: DisplayProcessor, longestValue: string }) {
  const actualConfig = config;
  const displayed = display(value);
  return (<BarGauge
    theme={actualConfig.theme2}
    field={{
      max,
      min: 0,
      fieldMinMax: true,
      color: {
        mode: 'fixed',
        fixedColor: color
      }
    }}
    valueDisplayMode={BarGaugeValueMode.Hidden}
    text={{
      titleSize: 15,
    }}

    alignmentFactors={{
      title: longestValue,
      text: ''
    }}

    width={width}
    height={5} // FUCK MY LIFE - version 9 requires height > 40 to respect top
    showUnfilled={false}
    namePlacement={BarGaugeNamePlacement.Left}
    value={{
      ...displayed,
      title: displayed.text || '\u2003',
      text: ""
    }}
  />);
}

type ThroughputField = Field & {
  max: number,
  longestValue: string,
  display: DisplayProcessor
}

type ThroughputRowProps = {
  row: Record<string, any>
  options: Props["options"]
  width: Props["width"]
  selectedRow: string | undefined,
  setSelectedRow: (str: string) => void,
  hoverItem: { id: any, x: number, y: number}  | undefined,
  setHoverItem: (item: { id: any, x: number, y: number}  | undefined) => void,
  theme: GrafanaTheme2
  firstStringColumn: Field
  primaryNumberColumn: ThroughputField,
  remainingNumberColumns: ThroughputField[]
}

function ThroughputRow({
  firstStringColumn,
  row,
  options,
  setSelectedRow,
  selectedRow,
  hoverItem,
  setHoverItem,
  theme,
  primaryNumberColumn,
  remainingNumberColumns,
  width
}: ThroughputRowProps) {
  return (
    <div
      key={row[firstStringColumn.name]}
      className='throughput-row'
      id={`row-${row[firstStringColumn.name]}`}
      onClick={(event) => {
        const currentUrlParam = locationService.getSearchObject()[`var-${options.variableName}`] as string;
        if (currentUrlParam !== `${row[firstStringColumn.name]}`) {
          setSelectedRow(`${row[firstStringColumn.name]}`);
          locationService.partial({[`var-${options.variableName}`]: row[firstStringColumn.name] });
        }
        else {
          setSelectedRow('');
          locationService.partial({[`var-${options.variableName}`]: 'All' });
        }
      }}
      style={{
        backgroundColor: selectedRow === `${row[firstStringColumn.name]}` ? "rgb(30, 33, 37)" : "",
        borderBottom: "1px solid #ccc",
        padding: "2px"
      }}
    >
      <label>{`${row[firstStringColumn.name]}`}</label>
      <Portal>
        {hoverItem?.id === row[firstStringColumn.name] &&
          <VizTooltipContainer
            position={{
              x: hoverItem?.x || 0,
              y: hoverItem?.y || 0
            }}
            offset={{
              x: 5,
              y: 5
            }}
          >
            <VizLegend
              displayMode={LegendDisplayMode.Table}
              placement='bottom'
              items={[primaryNumberColumn, ...remainingNumberColumns].map(field => ({
                label: `${field.name} ${field.display?.(row[field.name]).text}`,
                yAxis: 1,
                color: getFieldSeriesColor(field, theme).color
              }))}
            />
          </VizTooltipContainer>
        }
      </Portal>
      <div
        onMouseEnter={(e) => setHoverItem({ id: row[firstStringColumn.name], x: e.clientX, y: e.clientY })}
        onMouseLeave={() => setHoverItem(undefined)}
      >
        <ValuedBarGuage
          color={"green"}
          value={row[primaryNumberColumn.name]}
          max={primaryNumberColumn.max}
          width={width-20}
          title={`${primaryNumberColumn.name} ${row[primaryNumberColumn.name]}}`}
          longestValue={primaryNumberColumn.longestValue}
          display={primaryNumberColumn.display}
        />
        {remainingNumberColumns.map((column) => (
          <ValuedBarGuage
            key={column.name}
            color={"blue"}
            value={row[column.name]}
            max={column.max}
            width={width-20}
            title={`${column.name} ${row[column.name]}}`}
            longestValue={column.longestValue}
            display={column.display}
          />
        ))}
      </div>
    </div>
  )
}

export function TraceList({
  data,
  options,
  width,
  height,
  timeZone,
}: Props) {

  const startTime = 0;
  const endTime = 0;
  const timeRange: TimeRange = {
    from: dateTime(startTime),
    to: dateTime(endTime),
    raw: {
      from: dateTime(startTime),
      to: dateTime(endTime)
    }
  }
  const series = data.series.map(s => ({
    ...s,
    fields: s.fields.map(field => {
      const graphFieldConfig: GraphFieldConfig = {
        drawStyle: GraphDrawStyle.Bars,
        fillOpacity: 66,
        barAlignment: BarAlignment.Center,
        stacking: {
          mode: StackingMode.Normal
        },

        showPoints: VisibilityMode.Auto,
        pointSize: 5,
      }
      return {
        ...field,
        config: {
          ...field.config,
          custom: {
            ...field.config.custom,
            ...graphFieldConfig
          }
        }
      };
    })
  }));
  let selectedIdx: number | null | undefined;

  return (
    <DataSourcePicker
      onChange={async (selectedDataSource) => {
        const dataSource = await getDataSourceSrv().get({
          uid: selectedDataSource.uid,
        });
        dataSource.getDefaultQuery?.(CoreApp.PanelViewer);
        const result = await dataSource.query({
          app: CoreApp.PanelViewer,
          requestId: `${Math.random()}`.slice(2),
          timezone: timeZone,
          interval: '',
          intervalMs: 0,
          targets: [{
            datasource: {
              type: selectedDataSource.type,
              uid: selectedDataSource.uid
            },
            refId: 'A',
            limit: 20,
            queryType: "traceqlSearch",
            tableType: "traces",
            filters: [
              {id: 'c8fe8119', operator: '=', scope: 'span'} // dunno what this is
              // the method name attr
            ]
          }],
          maxDataPoints: -1,
          scopedVars: {},
          cacheTimeout: '0',

          // TODO: change this
          range: timeRange, // time?
          startTime: Date.now(),
          rangeRaw: timeRange.raw
        })
        console.log(result);
      }}
    />
  )

  return (
    <TimeSeries
      frames={series}
      height={height}
      width={width}
      legend={{
        displayMode: LegendDisplayMode.List,
        placement: 'bottom',
        calcs: [],
        showLegend: true
      }}
      timeRange={timeRange}
      timeZone={timeZone}
      options={{
        focus: 0.1
      }}
    >
      {(builder: UPlotConfigBuilder, alignedFrame: DataFrame) => {
        builder.addHook('init', (u: uPlot) => {
          u.over.addEventListener('click', (e) => {
            if (selectedIdx === u.cursor.idx || !u.cursor.idx) {
              selectedIdx = undefined;
              locationService.partial({[`var-${options.variableName}StartTime`]: undefined });
              locationService.partial({[`var-${options.variableName}EndTime`]: undefined });
            }
            else if (u.cursor.idx) {
              selectedIdx = u.cursor.idx;
              let startTime;
              let endTime;
              if (selectedIdx === 0) {
                startTime = series[0].fields[0].values[selectedIdx];
              }
              else {
                const prev = series[0].fields[0].values[selectedIdx - 1];
                const now = series[0].fields[0].values[selectedIdx];
                startTime = now - (now - prev) / 2;
              }
              if (selectedIdx === series[0].fields[0].values.length - 1) {
                endTime = series[0].fields[0].values[selectedIdx];
              }
              else {
                const next = series[0].fields[0].values[selectedIdx + 1];
                const now = series[0].fields[0].values[selectedIdx];
                endTime = now + (next - now) / 2;
              }
              locationService.partial({[`var-${options.variableName}StartTime`]: startTime });
              locationService.partial({[`var-${options.variableName}EndTime`]: endTime });
            }
          });
        });
        return (
          <div></div>
          );
      }}

    </TimeSeries>
  )
}

export function ThroughputList({
  data,
  options,
  width,
  height,
  timeRange,
  timeZone,
}: Props) {
  const urlParam = locationService.getSearchObject()[`var-${options.variableName}`] as string;
  const [selectedRow, setSelectedRow] = useState(urlParam);
  const theme = useTheme2();

  assertOptions(options, "throughputlist");

  const throughputOptions = options as ThroughputListFilterOptions;

  if (!data.series || data.series.length === 0) {
    return (<div>No Data</div>)
  }
  let preliminaryData = new Array(data.series[0].length).fill(0).map((_value, index) => {
    return Object.fromEntries(data.series[0].fields.map(({ name, values }) => [name, values[index] || values.get(index)]));
  });

  const sortField = throughputOptions.sortVariable && locationService.getSearchObject()[`var-${throughputOptions.sortVariable}`] as string;
  if (sortField) {
    preliminaryData.sort((rowA, rowB) => rowB[sortField] - rowA[sortField]);
  }
  if (throughputOptions.limitRows !== undefined) {
    preliminaryData = preliminaryData.slice(0, throughputOptions.limitRows);
  }
  const fieldsToUse = data.series[0].fields.map((field) => {
    return {
      ...field,
      values: preliminaryData.map(row => row[field.name])
    }
  });

  const primaryField = sortField || throughputOptions.primaryField;


  const firstStringColumn = fieldsToUse.find(({ type }) => type === "string" || type === "time");

  // limit to 2 for now.
  const allNumberColumns = fieldsToUse.filter(({ type }) => type === "number").map(field => {
    // at least in version 9 (prod/staging) this max is apparently the max across all values :|
    const max = /*field.state?.range?.max || */field.values.filter(value => !Number.isNaN(value)).sort((a, b) => b-a)[0];

    const displayProcessor = getDisplayProcessor({
      field,
      timeZone,
      theme
    });
    const longestValue = field.values.map(value => displayProcessor(value).text).sort((a, b) => `${b}`.length - `${a}`.length)[0];
    return {
      max,
      display: field.display || displayProcessor,
      // fill with the widest character
      longestValue: new Array(longestValue.length + 1).fill('0').join(''),
      ...field,
    };
  });

  const allLongestValue = allNumberColumns.map(a => a.longestValue).sort((a, b) => b.length - a.length)[0];
  allNumberColumns.forEach(field => field.longestValue = allLongestValue);
  const [hoverItem, setHoverItem] = useState<{ id: any, x: number, y: number}  | undefined>(undefined);

  const primaryNumberColumn = allNumberColumns.find((field, index) => {
    return primaryField ? field.name === primaryField : index === 0
  });

  if (!primaryNumberColumn) {
    return (<div>Couldnt find column {primaryField}</div>)
  }

  const remainingNumberColumns = allNumberColumns.filter(column => column !== primaryNumberColumn);

  if (!firstStringColumn || allNumberColumns.length === 0) {
    return (<div>Bad data!</div>);
  }

  const rowData = new Array(fieldsToUse[0].values.length).fill(0).map((_value, index) => {
    return Object.fromEntries(fieldsToUse.map(({ name, values }) => [name, values[index] ?? values.get(index)]));
  });
  return (
    <div>
      <VizLegend
        displayMode={LegendDisplayMode.List}
        placement='bottom'
        items={allNumberColumns.map(field => ({
          label: field.name,
          yAxis: 1,
          color: getFieldSeriesColor(field, theme).color
        }))}
      />
      {rowData.map(row => {
        return ThroughputRow({
          width,
          options,
          selectedRow,
          setSelectedRow,
          hoverItem,
          setHoverItem,
          firstStringColumn,
          primaryNumberColumn,
          remainingNumberColumns,
          row,
          theme
        })
      })}
    </div>
  )
}
